<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>
    </div>

    <div class="box-body">
      <form @keydown="form.onKeydown($event)" @submit.prevent="editBranch" v-if="branch && onProgress === false">

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label">
              Город
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('city_id') }">
              <multiselect :allow-empty="true"
                           :options="cities"
                           :searchable="false"
                           @input="() => {
                             form.city_id = city ? city.id : null;
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Выберите город"
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           track-by="id"
                           v-model="city">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
              <HasError :form="form" field="city_id"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="phone_one">
              Телефон #1
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('phone_one') }">
              <vue-tel-input id="phone_one"
                             name="phone_one"
                             mode="international"
                             v-bind='phone_config'
                             v-model="form.phone_one">
              </vue-tel-input>
              <HasError :form="form" field="phone_one"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="phone_two">
              Телефон #2
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('phone_two') }">
              <vue-tel-input id="phone_two"
                             name="phone_two"
                             mode="international"
                             v-bind='phone_config'
                             v-model="form.phone_two">
              </vue-tel-input>
              <HasError :form="form" field="phone_two"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="phone_three">
              Телефон #3
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('phone_three') }">
              <vue-tel-input id="phone_three"
                             name="phone_three"
                             mode="international"
                             v-bind='phone_config'
                             v-model="form.phone_three">
              </vue-tel-input>
              <HasError :form="form" field="phone_three"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="location_latitude">
              Широта
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('location_latitude') }">
              <input class="form-control"
                     id="location_latitude"
                     name="location_latitude"
                     type="text"
                     v-model="form.location_latitude">
              <HasError :form="form" field="location_latitude"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="location_longitude">
              Долгота
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('location_longitude') }">
              <input class="form-control"
                     id="location_longitude"
                     name="location_longitude"
                     type="text"
                     v-model="form.location_longitude">
              <HasError :form="form" field="location_longitude"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="email">
              Email
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('email') }">
              <input class="form-control"
                     id="email"
                     name="email"
                     type="email"
                     v-model="form.email">
              <HasError :form="form" field="email"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="schedule_weekdays">
              Расписание (будние)
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('schedule_weekdays') }">
              <input class="form-control"
                     id="schedule_weekdays"
                     name="schedule_weekdays"
                     type="text"
                     v-model="form.schedule_weekdays">
              <HasError :form="form" field="schedule_weekdays"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="schedule_weekend">
              Расписание (выходные)
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('schedule_weekend') }">
              <input class="form-control"
                     id="schedule_weekend"
                     name="schedule_weekend"
                     type="text"
                     v-model="form.schedule_weekend">
              <HasError :form="form" field="schedule_weekend"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="instagram_link">
              Ссылка на Instagram
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('instagram_link') }">
              <input class="form-control"
                     id="instagram_link"
                     name="instagram_link"
                     type="text"
                     v-model="form.instagram_link">
              <HasError :form="form" field="instagram_link"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="telegram_link">
              Ссылка на Telegram
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('telegram_link') }">
              <input class="form-control"
                     id="telegram_link"
                     name="telegram_link"
                     type="text"
                     v-model="form.telegram_link">
              <HasError :form="form" field="telegram_link"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <label class="col-md-2 control-label" for="map_link">
              Ссылка на Google Maps
            </label>
            <div class="col-md-10"
                 v-bind:class="{ 'has-error': form.errors.has('map_link') }">
              <input class="form-control"
                     id="map_link"
                     name="map_link"
                     type="text"
                     v-model="form.map_link">
              <HasError :form="form" field="map_link"/>
            </div>
          </div>
        </div>

        <vue-tabs class="panel">
          <v-tab :key="index"
                 :title="locale.toUpperCase()"
                 class="nav-tab-content"
                 v-for="(locale, index) in locales">
            <div class="form-group">
              <div class="row">
                <label class="col-md-2 control-label" for="title">
                  Название
                  <small>({{ locale.toUpperCase() }})</small>
                  <span class="req"/>
                </label>
                <div class="col-md-10"
                     v-bind:class="{ 'has-error': form.errors.has(locale + '.title') }">
                  <input class="form-control"
                         id="title"
                         required
                         type="text"
                         v-model="form[locale].title">
                  <HasError :field="locale + '.title'" :form="form"/>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <label class="col-md-2 control-label" for="address">
                  Адрес
                  <small>({{ locale.toUpperCase() }})</small>
                </label>
                <div class="col-md-10"
                     v-bind:class="{ 'has-error': form.errors.has(locale + '.address') }">
                                            <textarea class="form-control"
                                                      id="address"
                                                      rows="3"
                                                      v-model="form[locale].address">
                                            </textarea>
                  <HasError :field="locale + '.address'" :form="form"/>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <label class="col-md-2 control-label" for="description">
                  Описание
                  <small>({{ locale.toUpperCase() }})</small>
                </label>
                <div class="col-md-10"
                     v-bind:class="{ 'has-error': form.errors.has(locale + '.description') }">
                                            <textarea class="form-control"
                                                      id="description"
                                                      rows="6"
                                                      v-model="form[locale].description">
                                            </textarea>
                  <HasError :field="locale + '.description'" :form="form"/>
                </div>
              </div>
            </div>
          </v-tab>
        </vue-tabs>

        <br/>

        <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

        <Button :disabled="!validateData || form.busy"
                :form="form"
                :loading="form.busy"
                class="btn btn-lg btn-warning pull-right"
                style="min-width: 100px;">
          <i class="fa fa-edit"></i>
        </Button>
      </form>
    </div>
  </div>

</template>

<script>
import Form from 'vform';
import Multiselect from 'vue-multiselect';
import {VTab, VueTabs} from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
import {VueTelInput} from 'vue-tel-input';
import phone_config from './../../assets/data/vue-tel-input-config.json';
import LocaleService from "../../services/LocaleService";
import CitiesService from "@/services/CitiesService";

Form.axios = API.apiClient;

export default {
  name: 'edit',

  components: {
    VueTelInput,
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
    VueTabs, VTab,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  props: [
    'branchId',
  ],

  data: () => ({
    page_title: 'Изменить филиал',

    form: null,

    branch: null,

    cities: [],
    city: null,
    locales: [],

    phone_config: phone_config,

    onProgress: true,
  }),

  async created() {
    try {
      this.phone_config.inputOptions.required = false;

      const branch_response = await API.apiClient.get('/branch/' + this.branchId, {
        params: {
          relations: [
            'city',
          ],
        }
      });

      this.branch = branch_response.data;

      this.cities = await CitiesService.getItems();
      this.locales = await LocaleService.getItems();

      this.fillBranchForm();
    } catch (error) {
      errorHandler(error);
    }
    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.form.city_id && this.locales && this.locales.length > 0;
    },
  },

  methods: {
    fillBranchForm() {
      try {
        this.city = this.branch.city;

        this.form = new Form({
          city_id: this.branch.city.id,
          phone_one: '+' + this.branch.contact_details.phone_one.toString(),
          phone_two: '+' + this.branch.contact_details.phone_two.toString(),
          phone_three: '+' + this.branch.contact_details.phone_three.toString(),
          location_latitude: this.branch.contact_details.location_latitude,
          location_longitude: this.branch.contact_details.location_longitude,
          email: this.branch.contact_details.email,
          schedule_weekdays: this.branch.contact_details.schedule_weekdays,
          schedule_weekend: this.branch.contact_details.schedule_weekend,
          instagram_link: this.branch.contact_details.instagram_link,
          telegram_link: this.branch.contact_details.telegram_link,
          map_link: this.branch.contact_details.map_link,
        });

        this.locales.forEach(locale => {
          this.form[locale] = {
            title: null,
            address: null,
            description: null,
          }
        });

        this.locales.forEach(locale => {
          let translations = this.branch.translations.filter(obj => {
            return obj.locale === locale
          })[0];

          this.form[locale] = {
            title: translations.title,
            address: translations.address,
            description: translations.description,
          };
        });
      } catch (error) {
        errorHandler(error);
      }
    },

    async editBranch() {
      await this.form.put('/branch/' + this.branchId + '/edit').then(() => {
        showSuccess();
        setTimeout(() => this.$router.push('/branches'), 600);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped>

</style>
